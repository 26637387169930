import React from 'react';
import { File } from '@model/generated/index';
import { Layout } from '@components/global/Layout';
import { graphql, PageProps } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import { navigationItemsProjects } from '@model/projekte/navigationItemsProjects';
import { Meta } from '@components/global/Meta';

type DataProps = {
  image1: File;
  image2: File;
  image3: File;
  image4: File;
  image5: File;
};

type Props = PageProps<DataProps>;

export const query = graphql`
  query {
    image1: file(
      relativePath: { eq: "projekte/raum_zeit_piraten/image1.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image2: file(
      relativePath: { eq: "projekte/raum_zeit_piraten/image2.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image3: file(
      relativePath: { eq: "projekte/raum_zeit_piraten/image3.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image4: file(
      relativePath: { eq: "projekte/raum_zeit_piraten/image4.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
    image5: file(
      relativePath: { eq: "projekte/raum_zeit_piraten/image5.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
      }
      publicURL
    }
  }
`;

const Page: React.FC<Props> = (props) => {
  const { data } = props;
  return (
    <Layout title="Projekte" navigationItems={navigationItemsProjects}>
      <div className="flex">
        <div className="flex-auto p-32">
          <iframe
            title="WunderKammer-RaumZeitPiraten"
            className="h-256 lg:h-512"
            frameBorder="0"
            allow="fullscreen; display-capture; autoplay"
            src="https://www.youtube.com/embed/Mim6VpxcNAg"
            width="100%"
          />
          <div>
            <h3>
              RaumZeitPiraten - Tobias Daemgen, Jan Ehlen und Moritz Ellerich
            </h3>
            <p>
              Die <b>Entwürfe für Schwerelosigkeit</b> sind ortsspezifische,
              audiovisuelle Maschinen-Installationen und architektonische
              Technik-Interventionen bestehend aus Gerüststangen, altem
              Labormaterial, Lautsprechern, Leuchtdioden, Lasern, Linsen,
              Spiegeln, Motoren und Elektronik. Mit diesen Elementen entstehen
              vor Ort und im Internet, organisch konstruierte,
              multidimensionale, Hybrid-Umgebungen und raumgreifende,
              elektrische Mischwesen aus analogen und digitalen Technologien.
              Über verschiedenste optische und akustische Sensoren nimmt diese
              Kunstmaschinerie Impulse von physisch Anwesenden und online
              teilnehmenden Besucher*Innen auf und verarbeitet sie zu immer
              neuen, chaotischen Collagen ihrer Selbst. Ein
              MedienAnArchaeologischer Automat, der in seiner
              selbstreferentiellen Ungerichtetheit die Besucher*innen in seine
              Struktur integriert, ihre Wahrnehmung herausfordert und ins
              Taumeln bringt.
            </p>
            <GatsbyImage
              className="my-32 max-w-672"
              objectFit="contain"
              image={data.image2.childImageSharp.gatsbyImageData}
              alt="RaumZeitPiraten"
            />
            <p>
              Die <b>RaumZeitPiraten</b> sind ein Künstlerkollektiv und
              fortlaufendes Projekt von{' '}
              <b>Tobias Daemgen, Jan Ehlen und Moritz Ellerich</b>. In 2007
              haben sie begonnen als Kunstkollektiv zu arbeiten um den Spiralen
              egozentrischer Selbststimulation zu entgehen. Mit ihren
              ortsspezifischen, performativen Multimedia Installationen,
              Interventionen und Happenings entwerfen die <b>RaumZeitPiraten</b>{' '}
              ephemere Modelle von unperfekten Mensch-Maschine Gegenwelten mit
              denen sie berechnete Realitäten und maschinendominiertes Verhalten
              in Frage stellen. Mit selbstgebauten, optoakustischen Instrumenten
              missbrauchen und remixen sie uralte und hochmoderne auditive und
              visuelle Technologien für ihre alchemistischen, organisch
              improvisierten Licht und Klang Architekturen. Ihre Aktivitäten
              sind auf spielerisch experimentelle Verknüpfungen von Klang, Bild,
              Objekt, Raum und Zeit ausgerichtet mit denen sie sich auf eine
              wechselseitig erweiternde
              Multimedia­-Performance­-Surround­-Raumschiff­-Laboratoriums­-Reise
              zwischen Wissenschaft und Fiktion begeben.
            </p>
            <div className="flex">
              <GatsbyImage
                className="w-1/2 mr-16"
                objectFit="cover"
                image={data.image3.childImageSharp.gatsbyImageData}
                alt="RaumZeitPiraten"
              />
              <GatsbyImage
                className="w-1/2"
                objectFit="cover"
                image={data.image4.childImageSharp.gatsbyImageData}
                alt="RaumZeitPiraten"
              />
            </div>
            <div className="pt-32 -mb-16">
              <p>
                <b>Goldstücke 2020 in Gelsenkirchen</b>
              </p>
              <iframe
                title="Goldstücke 2020 in Gelsenkirchen"
                className="pb-32 h-256 lg:h-512"
                allow="fullscreen"
                frameBorder="0"
                src="https://archive.org/embed/raum-zeit-piraten-goldstucke-gelsenkirchen-2020"
                width="100%"
              />
            </div>
            <b>
              <a
                href="http://raumzeitpiraten.com"
                target="_blank"
                rel="noopener noreferrer"
              >
                Zur Webseite der Künstler
              </a>
            </b>
          </div>
        </div>
        <h1 style={{ writingMode: 'vertical-rl' }}>Hyprid11</h1>
      </div>
    </Layout>
  );
};

export const Head: React.FC<Props> = (props) => {
  return (
    <Meta
      description="Die Entwürfe für Schwerelosigkeit sind ortsspezifische,
      audiovisuelle Maschinen-Installationen und architektonische
      Technik-Interventionen bestehend aus Gerüststangen, altem
      Labormaterial, Lautsprechern, Leuchtdioden, Lasern, Linsen,
      Spiegeln, Motoren und Elektronik."
      publicURL={props.data.image4.publicURL}
      title="Hyprid11"
    />
  );
};

export default Page;
